import * as React from "react";
import {
	withPrismicUnpublishedPreview,
	componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";
import { Masthead } from "../components/layout/masthead";
import { Layout } from "../components/global/layout";
import { prismic } from "../site-config";
import { linkResolver } from "../utils/prismic-link-resolver";
import ProjectTemplate from "../templates/project-article";

const NotFoundPage = () => (
	<Layout
		pageTitle="Page Not Found"
		metaTitle="Page Not Found"
		metaDescription="Unfortunately the page you tried to access was unavailable."
		ogType="website"
	>
		<Masthead
			title="Page Not Found"
			summary="Unfortunately the page you tried to access was unavailable."
			cssClass="c-masthead--content"
		/>
		<div className="c-content">
			<div className="container">
				<div className="row">
					<div className="c-content__container cms-content">
						<p>This might be because:</p>
						<ul>
							<li>
								You may have typed the web address incorrectly.
								Please check the address and spelling ensuring
								that it does not contain capital letters or
								spaces.
							</li>
							<li>
								It is possible that the page you were looking
								for may have been moved, updated or deleted.
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</Layout>
);

export default withPrismicUnpublishedPreview(NotFoundPage, [
	{
		accessToken: prismic.accessToken,
		repositoryName: prismic.repo,
		linkResolver,
		componentResolver: componentResolverFromMap({
			project: ProjectTemplate,
		}),
	},
]);
